---
import Icon, { type IconName } from "@react/widget/Icon";
import clsx from 'clsx';

const sizes = {
	"sm": "px-4 py-[5px] text-sm",
	"md": "px-5 md:px-6 py-1.5 md:py-2 text-base",
	"lg": "px-6 md:px-8 py-2 md:py-[13px] text-base",
};
const roundeds = {
	"none": "",
	"sm": "rounded-sm",
	"md": "rounded-md",
	"lg": "rounded-lg",
	"full": "rounded-full"
}

type optionType = {
	class: string,
	sizes: typeof sizes,
	roundeds: typeof roundeds,
	icon: IconName,
	iconPosition: String
};

const default_opt:optionType = {
	class: "bg-white text hover:text-primary-hover active:bg-white [&_.icon]:text-primary group/btn w-full sm:w-fit",
	sizes: sizes,
	roundeds: roundeds,
	icon: "arrow-right",
	iconPosition: "right"
};

const styles = {
	"primary": {
		class: "bg-primary border border-primary shadow-y-sm hover:bg-primary-hover active:bg-primary-btn-active text-white group/btn w-full sm:w-fit",
	},
	"default": {},
	"link": {
		class: "!pl-0 pr-8 hover:text-primary active:text-gray-900 [&_.icon]:text-primary group/btn text-left !h-fit md:!h- w-fit"
	},
  "yellow": {
		class: "bg-[#f8b72d] border border-white text-white hover:bg-[#ed9625] hover:text-white group/btn w-full sm:w-fit",
	},
  "blue": {
		class: "bg-blue text-white hover:bg-blue-700 hover:text-white group/btn w-full sm:w-fit",
	},
	"gray": {
		class: "bg-gray text-white hover:bg-gray/80 group/btn w-full sm:w-fit",
	},
  "dropdown": {
		class: "text space-x-1.5 [&:focus_.icon]:rotate-180 [&:focus+.hidden]:block w-full sm:w-fit",
		icon: "chevron-down"
	},
};

interface Props extends Record<string, any> {
  href?: string,
	style?: keyof typeof styles,
	size?: keyof typeof sizes,
	rounded?: keyof typeof roundeds,
  border?: boolean,
	icon?: boolean | IconName,
  circleArrow?: boolean,
  circleBg?: string,
	iconPosition?: "left"|"right",
	class?: string,
	target?: ""|"_blank"|"_self"|"_parent"|"_top"|"framename"
}

const {href, style = "default", size = "lg", border = false, rounded = "full",icon = false, iconPosition = "right", circleArrow = false, circleBg = "bg-gray-50", class: className = "", dataAction, ...props}:Props = Astro.props;
const option = Object.assign(default_opt, styles[style]);
const config = {
	class: option.class,
	size: option.sizes[size],
	rounded: option.roundeds[rounded],
	icon: (typeof icon === "boolean") ? option.icon : icon,
	iconPosition: iconPosition
};
const slot = Astro.slots.has('default');
---
<a class={clsx('btn flex justify-center font-semibold transition duration-300 cursor-pointer [&>*]:align-middle', config.class, config.size, config.rounded, className, border && "border")} data-action={dataAction} href={href} {...props}>
	<div class="h-full flex items-center justify-center">
		{ (icon && iconPosition === "left") && (circleArrow ? (
			<div class={clsx(circleBg, "btn-iconbox mr-5 flex items-center justify-center p-2 rounded-full transition-all duration-150 ease-in-out group-hover/btn:translate-x-1.5")}>
				<Icon className="btn-icon w-4 h-4 text-red-500 transition-all duration-300" name={config.icon} />
			</div>
		): <Icon className={clsx(slot ? `mr-5`:'', `flex-none btn-icon transition-all duration-300 group-hover/btn:translate-x-1.5`)} name={config.icon} />) }
		{ slot && <span class="btn-text"><slot /></span>}
		{ (icon && iconPosition === "right") && (circleArrow ? (
			<div class={clsx(circleBg, "btn-iconbox ml-5 flex bg-gray-50 items-center justify-center p-2 rounded-full transition-all duration-150 ease-in-out group-hover/btn:translate-x-1.5")}>
				<Icon className="btn-icon w-4 h-4 text-red-500 transition-all duration-300" name={config.icon} />
			</div>
		): <Icon className={clsx(slot ? `ml-5` : '', `flex-none btn-icon transition-all duration-300 group-hover/btn:translate-x-2`)} name={config.icon} />) }
	</div>
</a>
<script src="@assets/js/gotosection.ts"></script>